import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PostCard from "../../components/PostCard";

import atato from "../../assets/image/jpeg/atato.jpg";
import ledger from "../../assets/image/jpeg/ledger.jpg";
import centre from "../../assets/image/jpeg/centre.jpg";

const BlogLatest = () => (
  <>
    {/* <!-- Blog section --> */}

    <Container>
      <Row className="align-items-center justify-content-center">
        <Col lg="4" className="mb-5">
          <PostCard
            img={ledger}
            date="Jun 28, 2022"
            title="Ledger adds yield earning capability via Alkemi Earn"
            exLink="https://www.coindesk.com/business/2022/06/28/ledger-live-adds-yield-earning-capability-via-alkemi-earn/"
          >
            Ledger Live has intregated with Alkemi Earn to be able to allow over
            1.5 million users...
          </PostCard>
        </Col>
        <Col lg="4" className="mb-5">
          <PostCard
            img={centre}
            date="Mar 10, 2022"
            title="Alkemi joins crypto goliaths to build DeFi Standards  "
            exLink="https://cointelegraph.com/news/ftx-joins-other-crypto-goliaths-to-promote-autonomy-over-sensitive-information"
          >
            Alkemi joins 14 crypto companies partnered with Verite, including
            Coinbase, Circle, FTX..
          </PostCard>
        </Col>
        <Col lg="4" className="mb-5">
          <PostCard
            img={atato}
            date="Mar 3, 2022"
            title="Alkemi Network x Atato | Custody Partnership"
            exLink="https://medium.com/alkemi/alkemi-network-x-atato-custody-partnership-4b1649f75f60"
          >
            We’re excited to announce our partnership between Alkemi Network and
            Atato ..
          </PostCard>
        </Col>
      </Row>
    </Container>
  </>
);

export default BlogLatest;
