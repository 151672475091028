import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Title, Section, Box } from '../../components/Core'

import BlogList from '../blog/BlogLatest'


const Latest = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section bg="offwhite">
      <Container>
        <Box pb={3}>
          {' '}
          <Title>Latest News & Blogs </Title>
        </Box>
        <Row className="justify-content-center">
          <BlogList />
        </Row>
        <Col xs="12" className="text-center mt-2">
          <div
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-once="true"
            data-aos-delay="500"
          >
          <Link to="/latest-news">

                <i className="fas fa-clipboard-list mr-2"></i>
                View All Articles
                </Link>
          </div>
        </Col>
      </Container>
    </Section>
  </>
)

export default Latest
