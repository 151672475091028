import React from 'react'
import styled from 'styled-components'
import { Title, Box, Text } from '../Core'
import { device } from '../../utils'
const Card = styled(Box)`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  overflow: hidden;
  background-color: white;

  &:hover {
    box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  }
`

const ImageContainerHorizontal = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`

const CardText = styled(Box)`
  padding: 30px;
`

const TitleStyled = styled(Title)`
  transition: 0.3s;
`

const PostCard = ({
  horizontal = false,
  img,
  exLink,
  date,
  title,
  children,
  ...rest
}) => (
  <Card
    className={horizontal ? 'd-flex flex-column flex-md-row' : ''}
    {...rest}
  >
    {horizontal ? (
      <ImageContainerHorizontal>
        <img src={img} alt="" className="w-100 img-fluid" />
      </ImageContainerHorizontal>
    ) : (
      <Box className="position-relative">
        <img src={img} alt="" className="w-100 img-fluid" />
      </Box>
    )}

    <CardText>
      {date && (
        <Text variant="small" fontSize={2} lineHeight={1.75} mb="14px">
          {date}
        </Text>
      )}
      <TitleStyled fontSize={4} variant="card" mb="14px">
        <a
          href={exLink}
          target="_blank"
          rel="noreferrer"
          className="stretched-link"
        >
          {title}
        </a>
      </TitleStyled>
      <Text variant="small" fontSize={2} lineHeight={1.75} mb="2px">
        {children}
      </Text>
    </CardText>
  </Card>
)

export default PostCard
