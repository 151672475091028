import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Title, Box, Section, Text } from "../../components/Core";

import logoOv from "../../assets/image/svg/ov2.svg";
import logoFractal from "../../assets/image/png/fractal.png";
import logoTrgc from "../../assets/image/png/trgc.png";
import logoMonerium from "../../assets/image/png/monerium.png";
import logoShift from "../../assets/image/svg/shift.svg";
import logoLedgerprime from "../../assets/image/png/ledgerprime.png";
import logoGcr from "../../assets/image/png/gcr.png";
import logoTechstars from "../../assets/image/png/techstars.png";
import logoConsesys from "../../assets/image/png/consensys.png";
import logoD64 from "../../assets/image/png/d64.png";
import logoRadar from "../../assets/image/png/radar.png";
import logoAutonomy from "../../assets/image/png/autonomy.png";
import logoJst from "../../assets/image/png/jst.png";
import logoGenesis from "../../assets/image/png/genesis.png";
import logoGsr from "../../assets/image/png/gsr.png";
import logoGbv from "../../assets/image/png/gbv.png";
import logoA195 from "../../assets/image/png/a195.png";
import logoValidation from "../../assets/image/png/validation.png";
import logoEllipal from "../../assets/image/png/ellipal.png";
import logoAsymmetries from "../../assets/image/png/asymmetries.png";

const Brand = styled(Box)`
  opacity: 0.85;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Partners = () => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Section
      bg="primary"
      className="position-relative"
      pt="150px !important"
      pb="120px !important"
    >
      <Box pb={"10px"}>
        <Container>
          <Box pb={5}>
            {" "}
            <Title color="light">Partners & Investors </Title>
            <Text color="lighterShade">
              Alkemi Network is forging strategic partnerships with leading
              enterprises in the Web3 ecosystem.
            </Text>
          </Box>
        </Container>
        <Container>
          <Box
            className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
            mx="-32px"
          >
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://outlierventures.io"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoOv} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.ledgerprime.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoLedgerprime} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://consensys.net" target="_blank" rel="noreferrer">
                <img src={logoConsesys} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://monerium.com" target="_blank" rel="noreferrer">
                <img src={logoMonerium} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://jstcap.com" target="_blank" rel="noreferrer">
                <img src={logoJst} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2} Width="140px">
              <a
                href="https://www.shiftmarkets.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoShift} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.techstars.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoTechstars} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://trgc.io/" target="_blank" rel="noreferrer">
                <img src={logoTrgc} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://validation.capital"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoValidation} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4}>
              <a
                href="https://www.ellipal.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoEllipal} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://d64.vc" target="_blank" rel="noreferrer">
                <img src={logoD64} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://radarrelay.com" target="_blank" rel="noreferrer">
                <img src={logoRadar} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.autonomy.capital/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoAutonomy} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://genblock.capital"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoGenesis} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4}>
              <a href="https://www.gsr.io" target="_blank" rel="noreferrer">
                <img src={logoGsr} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.fractaldistributed.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoFractal} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.asymmetriestech.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoAsymmetries} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a href="https://gbv.capital" target="_blank" rel="noreferrer">
                <img src={logoGbv} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://www.a195.capital"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoA195} alt="" className="img-fluid" />
              </a>
            </Brand>
            <Brand className="" py={4} mx={4} mb={2}>
              <a
                href="https://globalcoinresearch.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoGcr} alt="" className="img-fluid" />
              </a>
            </Brand>
          </Box>
        </Container>
      </Box>
    </Section>
  </>
);

export default Partners;
