import React, { useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import { Title, Button, Section, Box, Text, Span } from '../../components/Core'

import iconCapital from '../../assets/image/svg/capital-thick.svg'
import iconConnect from '../../assets/image/svg/connect-thick.svg'
import iconControl from '../../assets/image/svg/control-thick.svg'
import GlobalContext from "../../context/GlobalContext";


const SectionStyled = styled(Section)`
  background-image: linear-gradient(
    137deg,
    rgba(67, 67, 255, 0.07) 0%,
    rgba(84, 84, 212, 0) 100%
  );
`

const ButtonStyled = styled(Button)`
  border: solid 3px;
  &:before {
    background: ${({ theme, color }) => theme.colors[color]};
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.light};
    border: solid 3px ${({ theme }) => theme.colors.primary};
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    background: ${({ theme, color }) => theme.colors[color]};
  }
`

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 3]}>
      <img src={iconImage} width="50px" alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" fontWeight="700" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
)

const Hero = () => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col md="10" lg="10" xl="10">
              <Box mb={96}>
                <Title my={4} variant="hero" fontWeight="600">
                  Alkemi Network
                  <br /><Span color="primary">Bridging</Span> CeFi to DeFi
                </Title>
                <Title pt={3} variant="heroSubtitle">
                  Alkemi Network is an institution-grade liquidity network facilitating professional DeFi for financial institutions and individuals to earn yields on their Ethereum-based digital assets.
                </Title>
                <Box pt={5}>
                    <ButtonStyled onClick={openVideoModal} variant="outlined" color="primary">
                        <i className="fas fa-play-circle mr-2"></i> Watch Intro
                    </ButtonStyled>
                </Box>
              </Box>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="4" className="mb-5 mb-lg-4">
              <FeatureCard title="Capital." iconImage={iconCapital}>
                Source deep liquidity for digital assets via permissioned and permissionless pools. Alkemi's compliant liquidity pools provide a trusted counterparty environment.
              </FeatureCard>
            </Col>
            <Col lg="4" className="mb-5 mb-lg-4">
              <FeatureCard title="Connectivity." iconImage={iconConnect}>
              Unlock turnkey access to DeFi features and functionality. Alkemi's solutions are optimized for seamless access between Web2 and Web3 digital asset allocators.
              </FeatureCard>
            </Col>
            <Col lg="4" className="mb-5 mb-lg-4">
              <FeatureCard title="Control." iconImage={iconControl}>
             Discover advanced risk management and reporting features via web browser or API access. Minimize risk and maximize value with technology tailored for sophisticated investors.
              </FeatureCard>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  )
}

export default Hero
