import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Section } from '../../components/Core'

const Intro = () => (
  <>
    <Section bg="primary" pt="100px !important" pb="75px !important">
      <Container>
        <Row>
          <Col lg="12">
            <div>
              <Title variant="intro" color="light">
              The first decentralized liquidity network to facilitate both KYC permissioned and permissionless liquidity pools governed by one network utility token.
              </Title>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Intro
