import React from "react";
import Hero from "../sections/version1/Hero";
import Intro from "../sections/version1/Intro";
import Partners from "../sections/version1/Partners";
import Earn from "../sections/version1/Earn";
import Customers from "../sections/version1/Customers";
import Faq from "../sections/version1/Faq";
import BlogRecent from "../sections/version1/BlogRecent";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/1948713.js"
        ></script>
        <title>
          Alkemi Network - Compliant DeFi for Institutions & Individuals
        </title>
        <meta
          name="description"
          content="Alkemi Network is an institution-grade liquidity network for institutions and individuals to access compliant DeFi and earn yields through borrowing and lending."
        />
        <meta
          name="keywords"
          content="DeFi, Lending, institutions, compliant"
        />
        <meta
          property="og:title"
          content="Alkemi Network - Compliant DeFi for Institutions & Individuals"
        />
        <meta
          property="og:description"
          content="Alkemi Network is an institution-grade liquidity network for institutions and individuals to access compliant DeFi and earn yields through borrowing and lending."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://alkemi.network/" />
        <link rel="canonical" href="https://alkemi.network/" />
      </Helmet>
      <PageWrapper footerDark>
        <Hero />
        <Intro />
        <Earn />
        <Customers />
        <Faq />
        <Partners />
        <BlogRecent />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
