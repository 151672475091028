import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { rgba } from 'polished'

import { Title, Section, Box, Text } from '../../components/Core'
import { device } from '../../utils'


const SectionStyled = styled(Section)`
  position: relative;
  &::after {
    content: '';
    left: 0;
    bottom: 0;
    height: 15%;
    width: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.ash}!important;
  }
`
const ColStyled = styled(Col)`
margin-top: 0px;

@media ${device.sm} {
  margin-top: 0px;
}
@media ${device.lg} {
  margin-top: 20px;
 }
}
`

const ULStyled = styled.ul`
  list-style: none;
  max-width: 400px;
  margin-top: 30px;
  padding-left: 0;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: '\f00c';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`

const CardCustomers = styled(Box)`
  box-shadow: ${({ theme }) => `0 32px 34px ${rgba(theme.colors.dark, 0.8)}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.ash}!important;
  padding: 50px 60px 40px 50px;
  margin-bottom: 30px;
  }
`

const Customers = () => {
  return (
    <>
      {/* <!-- Customer section --> */}
      <SectionStyled bg="#000000" pt="150px !important" pb="20px !important">
        <Container
          className="position-relative"
          css={`
            z-index: 1;
          `}
        >
          <Row>
            <Col lg="6">
            <Box mb={5}>
              <Title color="light">Our Customers</Title>
              <Text color="lighterShade" pr={5}>
              Alkemi Network provides the technology for digital asset allocators, exchanges, custodians and individuals to access decentralized financial structured products.
              </Text>
            </Box>
              <CardCustomers>
                <div>
                  <div className="d-flex align-items-end">
                    <Title color="light" variant="card" fontSize="24px" mb={4}>
                      Centralized Institutions
                    </Title>
                  </div>
                  <Text variant="small" color="lighterShade" fontSize="16px">
                   Access to DeFi lending protocols is restricted for centralized counterparties due to compliance risk, a lack of liquidity, limited visibility and insufficient transaction reporting. Alkemi Earn solves these issues by enabling digital asset allocators to borrow and lend with institution-grade functionality. {' '}
                  </Text>
                  <ULStyled>
                    <li>Access to Compliant DeFi Pools</li>
                    <li>Multisig Wallet Functionality </li>
                    <li>Institution-grade Reporting </li>
                  </ULStyled>
                </div>
              </CardCustomers>
            </Col>
            <ColStyled lg="6" className="mb-5">
              <CardCustomers>
                <div>
                  <div className="d-flex align-items-end">
                    <Title color="light" variant="card" fontSize="24px" mb={4}>
                     Centralized Exchanges
                    </Title>
                  </div>
                  <Text variant="small" color="lighterShade" fontSize="16px">
                    Exchanges are limited in providing customers with direct access to DeFi protocols due to compliance, counterparty and infrastructure risks. Alkemi Earn solves these issues by providing exchanges with an embedded DeFi user interface, enabling their customers to borrow and lend from their own platform.{' '}
                  </Text>
                  <ULStyled>
                    <li>Access to Compliant DeFi Pools</li>
                    <li>Alkemi Bridge SDK</li>
                    <li>Embedded User Interface</li>
                  </ULStyled>
                </div>
              </CardCustomers>
              <CardCustomers>
                <div>
                  <div className="d-flex align-items-end">
                    <Title color="light" variant="card" fontSize="24px" mb={4}>
                    Private Individuals
                    </Title>
                  </div>
                  <Text variant="small" color="lighterShade" fontSize="16px">
                   Individuals have limited access to detailed trade reporting, risk management and user experience within the current DeFi protocol offerings. Alkemi Earn solves these issues with comprehensive reporting and risk management provided through an intuitive interface. {' '}
                  </Text>
                  <ULStyled>
                    <li>Access to Open Liquidity Pools</li>
                    <li>User-friendly Interface</li>
                    <li>Comprehensive Reporting Features </li>
                  </ULStyled>
                </div>
              </CardCustomers>
            </ColStyled>
          </Row>
        </Container>
      </SectionStyled>
    </>
  )
}

export default Customers
