import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box, Section, Text } from "../../components/Core";

import iconKyc from "../../assets/image/svg/kyc.svg";
import iconOpen from "../../assets/image/svg/open.svg";
import iconReport from "../../assets/image/svg/report.svg";
import iconMulti from "../../assets/image/svg/multi.svg";
import imgDash from "../../assets/image/png/alkdash-new.png";

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest} display="flex" mr={4} mb={4}>
    <Box minWidth="fit-content" marginRight="26px">
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3} color="light">
        {title}
      </Title>
      <Text variant="small" color="lighterShade">
        {children}
      </Text>
    </Box>
  </Box>
);

const Earn = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="ash"
      className="position-relative"
      pt="150px !important"
      pb="80px !important"
    >
      <Container>
        <Row>
          <Col lg="9">
            <div>
              <Title color="light">Earn Interest with Alkemi Earn</Title>
              <Text color="lighterShade">
                Borrow and lend across a range of digital asset markets
                including ETH, WBTC, DAI and USDC.
              </Text>
            </div>
          </Col>
        </Row>
        <Box
          borderRadius={5}
          mt={5}
          className="position-relative overflow-hidden"
        >
          <img src={imgDash} alt="" className="img-fluid" />
        </Box>
        <Box mt={6}>
          <Col md="12">
            <Row className="justify-content-center">
              <Col lg="6" className="mb-6 mb-lg-5">
                <FeatureCard
                  title="KYC Approved Liquidity Pool"
                  iconImage={iconKyc}
                >
                  Unlock guaranteed compliant access to Alkemi Network's
                  permissioned digital asset pool, 'Verified'. Participate in a
                  trusted-counterparty environment of allow-listed users.
                </FeatureCard>
              </Col>
              <Col lg="6" className="mb-6 mb-lg-5">
                <FeatureCard
                  title="Open Access Liquidity Pool"
                  iconImage={iconOpen}
                >
                  The permissionless liquidity pool, 'Open' facilitates a true
                  DeFi experience for participants whilst maintaining advanced
                  reporting and risk management features.
                </FeatureCard>
              </Col>
              <Col lg="6" className="mb-6 mb-lg-5">
                <FeatureCard
                  title="Institution-grade Reporting"
                  iconImage={iconReport}
                >
                  On-demand transaction, asset allocation and balance reports
                  provided as standard.
                </FeatureCard>
              </Col>
              <Col lg="6" className="mb-5 mb-lg-5">
                <FeatureCard
                  title="Web3 and Multisig Wallet Support "
                  iconImage={iconMulti}
                >
                  Risk and transaction fees minimized, liquidity and control
                  maximized, all streamlined with multiple Web3 wallet support.
                </FeatureCard>
              </Col>
            </Row>
          </Col>
        </Box>
      </Container>
    </Section>
  </>
);

export default Earn;
