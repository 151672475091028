import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Span, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  padding-top: 30px;
`;

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`;

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`;

const Faq = () => (
  <>
    {/* <!-- Faq section --> */}
    <SectionStyled bg="ash" pb="130px !important">
      <Container>
        <Col lg="9">
          <Box mb={5}>
            <Title color="light">Frequently Asked Questions</Title>
          </Box>
        </Col>
        <Row>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>
                  Is there a Liquidity Mining Program available?
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Yes, Alkemi Earn participants in the 'Verified' and 'Open'
                  pools are eligible for ALK token rewards. See the Liquidity
                  Mining Program{" "}
                  <a
                    href="https://app.gitbook.com/@alkemi-network/s/alkemi-network-docs/liquidity-mining-program"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Span color="primary">documentation.</Span>
                  </a>{" "}
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Will there be an Alkemi Network token?</FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Yes, Alkemi Network has a native governance utility token,
                  ALK. ALK tokens allow users to help decide the future of the
                  network.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>
                  Do I have to undergo KYC verification to use Alkemi Earn?
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  No, only counterparties wishing to access the 'Verified' pool
                  are required to succesfully complete KYC / AML verification.
                  Other participants can access the 'Open' pool
                  permissionlessly.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>
                  Which digital assets are supported in Alkemi Earn?
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  ERC-20 compatible assets including ETH, WBTC and stablecoins
                  including USDC and DAI are available in the KYC / AML
                  permissioned 'Verified' pool. ETH and USDC are available in
                  the permissionless 'Open' pool.
                </Text>
              </div>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Faq;
